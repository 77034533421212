import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const EventDocumentsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Category ID',
        dataKey: 'documentCategoryId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'File Name',
        dataKey: 'fileName',
        percWidth: 40,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Document Name',
        dataKey: 'documentName',
        percWidth: 40,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Event Id',
        dataKey: 'eventId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
];

export const EventDocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Event Documents',
        blockType: 'header',
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',  
        colWidth: 12,
        disabled: true
    },
    {
        label: 'Event Id',
        dataKey: 'eventId',
        blockType: 'input',    
        colWidth: 6,
        disabled: true,
    },
    {
        label: 'Display Sequence',
        dataKey: 'displaySequence',
        blockType: 'input',    
        colWidth: 6,
        disabled: true,
    },
    {
        label: 'File Name',
        dataKey: 'fileName',
        blockType: 'input',
        colWidth: 12,
        disabled: true,
    }
];

export const EventDocumentsHistoryTableColumns: IDynamicTableColumn[] = [
    {
        header: 'User ID',
        dataKey: 'userId',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Name',
        dataKey: 'fullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Email',
        dataKey: 'logonName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Organisation',
        dataKey: 'organisationId',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
];

export const UploadDocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',
        colWidth: 4,
    },
    {
        label: 'Category',
        dataKey: 'documentCategoryId',
        blockType: 'select',
        options: [
            { id: 1, name: 'Report' },
            { id: 2, name: 'CaseStudy' },
            { id: 3, name: 'Presentation' },
            { id: 4, name: 'Toolkit' },
            { id: 6, name: 'Bespoke' },
            { id: 7, name: 'Specification' },
        ],
        colWidth: 4
    },
    {
        label: 'Visible',
        dataKey: 'isVisible',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        blockType: 'saveButton',
        dataKey: 'documentName',
        dataKeys: ['documentName', 'documentCategoryId', 'isVisible'],
        label: 'Upload Document',
        action: 'create'
    }
]