import { Component, Input, OnInit} from '@angular/core';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { BoardTableColumns, NACELOverviewEnglandWales, NHSEILDRegistrationAddresses, NHSILDStaffSurveyDataExtract, OrganisationDeputyProductLeads, OrganisationProductLeads, ProductAndDeputyProductLeads, ProductLeads, ProjectAndDeputyProjectLeads, UserRolesForCAMHS, UserRolesForMH, UsersFromAcuteOrganisations } from './admin-report-details-layouts';
import { Layout } from 'src/app/core/classes/layout';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-report-details',
  templateUrl: './admin-report-details.component.html',
  styleUrls: ['./admin-report-details.component.scss']
})
export class AdminReportDetailsComponent implements OnInit{
  @Input() public currentAdminReports: any;
  public loadingError: string = null;
  public layout: ILayout;
  public globalTableColumns: Array<IDynamicTableColumn>
  public reportDetail: any
  public currentReportInfo: any;
  public adminReports: any;
  public currentReportName: string;
  public currentYear: number = undefined;
  public currentUrlParams: any;

  constructor(    
  public loaderService: LoaderService,
  private projectService: ProjectService,
  private router: Router,
  private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getReports()
    this.route.params.subscribe((params) => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
  }

  routeChange(params){
    this.currentAdminReports = params.id
    if(params.year){
      this.currentYear = params.year
      this.getReport()
    }
    }
  

  public getReport(){
    this.projectService.getAdminReportData(this.currentAdminReports, this.currentYear).subscribe(success => {
      this.reportDetail = success.data.adminReportData
      this.findReport()

    },
    error => { 
      console.log('Error: ', error);
    }
  )
  }

  public getReports(){
    this.projectService.getAdminReports().subscribe(success => {
      this.adminReports = success.data.adminReportList
      this.getReport()
    },
    error => { 
      console.log('Error: ', error);
    }
  )
  }

  public findReport(){
    let currentReport: any = this.currentAdminReports
    this.currentReportInfo = this.adminReports.find(a => a.reportId == currentReport)
    this.currentReportName = this.currentReportInfo.reportName
    this.tableColumnChanger()
    this.layout = new Layout(
      this.currentReportName,
      [], ''
    );
  }

  public yearChange(year: number): void {
    this.currentYear = year
    this.router.navigate(['tool/custom-reports',this.currentAdminReports,this.currentYear]);
  }

  public defaultColumnMaker(){
    let tableColumn = []
    let keys = Object.keys(this.reportDetail[0])
     keys.forEach(a => {
      tableColumn.push({
        backgroundActive: false,
        dataKey: a,
        filterSelected: false,
        groupable: true,
        header: a.replace(/_/g, ' ').split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" "),
        options: [],
        percWidth: 20,
        searchable: true,
        sortable: true,
      })
    })

    this.reportDetail.forEach(item => {
      for (const [key, value] of Object.entries(item)) {
        tableColumn.forEach(col => {
          if(col.dataKey == key){
            col.options.push(value)
          }
        })
      }
    })
    this.globalTableColumns = tableColumn
  }

  public tableColumnChanger(){
    switch(this.currentReportName) {
      case 'Board contacts':
        this.globalTableColumns = BoardTableColumns
        break;
      case 'Product leads':
        this.globalTableColumns = ProductLeads
        break;
      case "User Roles for MH":
          this.globalTableColumns = UserRolesForMH
         break;
      case 'Organisation product leads':
          this.globalTableColumns = OrganisationProductLeads
      break;
      case 'User Roles for CAMHS':
          this.globalTableColumns = UserRolesForCAMHS
        break;
      case 'Users from Acute Organisations':
          this.globalTableColumns = UsersFromAcuteOrganisations
          this.reportDetail.forEach(a => a.last_logon ? a.last_logon = moment(a.last_logon).format('LLL') : null) 
        break;
      case 'NHSI LD staff survey data extract':
          this.globalTableColumns = NHSILDStaffSurveyDataExtract
        break;
      case 'NHSEI LD Registration Addresses':
          this.globalTableColumns = NHSEILDRegistrationAddresses
        break;
      case 'Organisation deputy product leads':
          this.globalTableColumns = OrganisationDeputyProductLeads
        break;
      case 'Product and deputy product leads':
          this.globalTableColumns = ProductAndDeputyProductLeads
        break;
      case 'Project and deputy project leads':
          this.globalTableColumns = ProjectAndDeputyProjectLeads
        break;
        case 'NACEL Overview - England & Wales':
          this.globalTableColumns = NACELOverviewEnglandWales
        break;
        case 'NACEL Overview - Northern Ireland':
          this.globalTableColumns = NACELOverviewEnglandWales
        break;
      default:
        this.defaultColumnMaker()
    }
  }
}
