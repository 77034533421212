import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/core/classes/event';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { EventsService } from 'src/app/core/services/events.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { EventDocumentsDynamicForm, EventDocumentsHistoryTableColumns, EventDocumentsTableColumns, UploadDocumentsDynamicForm } from './event-document-layouts';
import { saveAs } from "file-saver";
import { IEventDocument } from 'src/app/core/models/event-document.vm';
import { IAlert } from 'src/app/core/models/alert';
import { UploaderService } from 'src/app/core/services/uploader.service';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-event-document',
  templateUrl: './event-document.component.html',
  styleUrls: ['./event-document.component.scss']
})
export class EventDocumentComponent implements OnChanges {

  @Input() currentEvent: Event;
  @Input() currentYear: number;

  public eventDocumentsTableColumns: Array<IDynamicTableColumn> = EventDocumentsTableColumns;
  public eventDocumentsHistoryTableColumns: Array<IDynamicTableColumn> = EventDocumentsHistoryTableColumns;
  public questionGroupForm: Array<IDynamicFormBlock> = EventDocumentsDynamicForm;
  public UploadDocumentsDynamicForm: Array<IDynamicFormBlock> = UploadDocumentsDynamicForm;
  public alert: IAlert;
  public componentName:string = "event-document";

  public displayedDocuments: Array<IEventDocument>;
  public displayedDocument: IEventDocument;

  public documentLayout: Layout;
  public loadingError: string;
  public currentQueryParams: any;
  public dynamicButtons = [
    {
      class: 'btn btn-success',
      name: '+ Add Document'
    }
  ];
  public newDocumentLayout: Layout;
  public UploadDocumentData: { documentName: string; displaySequence: string; };
  public files: any;


  constructor(
    public loaderService: LoaderService,
    private eventsService: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private Uploader: UploaderService,
  ) { 
    this.UploadDocumentData = {
      documentName:'',
      displaySequence:'',
    }
  }

  ngOnChanges(): void {
    // Check for open slider
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });

    if (!this.currentEvent.awsDocumentsDynamic) {
      this.getEventDocuments(this.currentEvent.eventId, this.currentYear);
    }
  }

  private getEventDocuments(eventId: number, currentYear: number): void {
    this.eventsService.getEventDynamicDocuments(eventId, currentYear).subscribe(
      success => {
        if (success.data.documentList) {
          this.currentEvent.awsDocumentsDynamic = success.data.documentList
        } else {
          this.currentEvent.awsDocumentsDynamic = [];
        }

        // Open slider, if in params
        if (this.currentQueryParams.id) {
          this.onTableRowClick(this.currentQueryParams.id);
        }
       },
      error => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public getAwsDocuments() {
    this.eventsService.getEventDynamicDocument(this.displayedDocument.documentId).subscribe(s => {
      saveAs(s.data.documentUrl, this.displayedDocument.fileName);
    },
    e => {
      console.log(e)
    })
}

  public onTableRowClick = (primaryKey: string): void => {
    this.displayedDocuments = this.currentEvent.awsDocumentsDynamic;
    this.displayedDocument = this.displayedDocuments.find(document => document.documentId == +primaryKey);
    this.documentLayout = new Layout(
      this.displayedDocument.documentName,
      [ `ID: ${this.displayedDocument.documentId}` ],
      null
    )
    this.router.navigate([], { queryParams: { id: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeQuestionSlider = (): void => {
    this.documentLayout = null;
    this.newDocumentLayout = null;
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  }

  public newDocumentClick() {
    this.newDocumentLayout = new Layout(
      'New Document Upload',
      ['Please upload a document file'],
      null
    )
  }

  public saveFiles(files){
    this.files = files
  }

  public dataUpdate(event){
    if(this.files && event.action == 'create') {
      this.uploadDocuments(event.row);
    } else if(event.action == 'create') {
      this.alert = {
        message: `<strong>File not detected</strong>`,
        alertClass: 'danger',
        fadeOut: true,
      }
    }
  }

  public uploadDocuments(form){
    if (form.organisationId == undefined) {
      form.organisationId = ''
    }
    this.Uploader.eventsDynamicDocumentUpload(this.files, form, this.currentEvent.eventId, this.currentYear).subscribe(success => {
      this.closeQuestionSlider()
      this.getEventDocuments(this.currentEvent.eventId, this.currentYear);
    },
    error => { 
      console.log('Error: ', error);
      if(error.error.includes('Invalid file extension. Allowed extensions are .xls, .xlsx, .pdf.')){
        this.alert = {
          message: `<strong>Invalid file type: please use .xls, .xlsx, .pdf.</strong>`,
          alertClass: 'danger',
          fadeOut: true,
      }}else{
        this.alert = {
          message: `<strong>Upload Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
    };
      }
    })
  }


}

